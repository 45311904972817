import * as React from "react";
import { makeDialogDraggable } from 'dialog-draggable';

makeDialogDraggable();

let nonModal = false;

export type ComponentFactory = (
    onSubmit: (d: any) => void,
    onClose: () => void) => React.ReactNode

export type DialogArguments = {
    factory: ComponentFactory,
    size?: "md" | "xl" | "lg" | "recorder",
    className?: string,
    nonModal?: boolean
}

let closeCallbacks: (() => void)[] = [];

const callCloseCallbacks = (call: boolean) => {
    if (call && closeCallbacks.length > 0) {
        closeCallbacks.forEach(c => c.call(window))
    }

    closeCallbacks = [];
}

export const setDialogCloseCallback = (cb: () => void) => {
    closeCallbacks.push(cb);
}

const DialogServiceContext = React.createContext<
    (args: DialogArguments) => Promise<any>>(Promise.reject);

export const useDialog = () =>
    React.useContext(DialogServiceContext);

export const DialogServiceProvider = ({ children }: { children: React.ReactNode }) => {
    const [
        dialogComponent,
        setDialogComponent
    ] = React.useState<React.ReactNode | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: (val: any) => void;
        reject: () => void;
    }>();

    const dialog = React.useRef<HTMLDialogElement | undefined>(undefined);
    const setRefAndOpenDialog = React.useCallback((node: HTMLDialogElement) => {
        if (node) {
            if (nonModal) {
                node.show();
            } else {
                node.showModal();
            }
            dialog.current = node;
            node.addEventListener("cancel", (event) => {
                handleClose();
            });
        }
    }, []);

    const openDialog = (args: DialogArguments) => {

        if (dialogComponent) {
            return Promise.reject("Already in use. More than one dialog = bad UX");
        }

        nonModal = args.nonModal === true;

        const element = <dialog ref={setRefAndOpenDialog} className={[args.className, args.size || "md"].join(" ")}>
            {args.factory(handleSubmit, handleClose)}
        </dialog>;

        setDialogComponent(element);

        // TODO - later when Promise.withResolvers is common - can be done like this:
        // let { promise, resolve, reject } = Promise.withResolvers();
        // awaitingPromiseRef.current = { resolve, reject };
        // return promise;

        return new Promise<any>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });

    };

    const closeDialog = (doCallbacks: boolean) => {
        dialog.current && dialog.current.close();
        setTimeout(() => {
            setDialogComponent(null);
            callCloseCallbacks(doCallbacks);
        }, 10);
    }

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        closeDialog(false);
    };

    const handleSubmit = (val: any) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(val);
        }
        closeDialog(true);
    };

    return (
        <>
            <DialogServiceContext.Provider
                value={openDialog}
                children={children}
            />
            {dialogComponent}

        </>
    );
};
