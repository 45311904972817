import {  atom, getDefaultStore } from "jotai";
import api from "../../services/api/axiosService";
import { ImageAlignData, ImagePickerAddImagePostDto, ImagePickerImageData, ImagePickerImageDto, ImagePickerImageListDto, ImagePickerPermissionDto, ImagePickerState, align, subtabstate, tabstate } from "./ImagePicker_types";
import { UserMailDto } from "../../services/user/types";
import fileUploadservice from "../FileUpload/FileUploadService";


const initialState: ImagePickerState = {
    imageData: undefined,
    tab: 'browse',
    subTab: 'preview',
    selectedImage: undefined,
    filteredImages: undefined,
    imageAlign: 'none',
    imagePreviewWidth: 0,
    isUploading: false,
    currentImage: undefined,
    deleteRequested: false,
    alignData: undefined,
}


const imageAtomPrivate = atom({...initialState});
export const imageAtom = atom(get => get(imageAtomPrivate));
imageAtomPrivate.debugLabel = "ImagePickerServiceAtom";

export class ImagePickerService {

    store = getDefaultStore();

    constructor() {
       
    }

    private onSubmitPicker = (res: any) => { };
    public submitBrooker = (res: any) => {
        this.onSubmitPicker && this.onSubmitPicker(res);
    }

    public setSubmitBrooker(method: (res: any) => void) {
        this.onSubmitPicker = method.bind(this);
    }


    setImagesData(images: ImagePickerImageListDto) {

        const state = { ...this.store.get(imageAtomPrivate) };

        state.imageData = images;
        state.selectedImage = undefined;
        state.tab = 'browse';
        state.imageAlign = 'none';
        state.imagePreviewWidth = 0;
        state.isUploading = false;
        state.currentImage = undefined;
        state.deleteRequested = false;
        state.subTab = 'preview';
        if (images != null) {
            state.filteredImages = images.Images;
        }
        else {
            state.filteredImages = undefined;
        }

        this.store.set(imageAtomPrivate, state);


    };

    setModalState(pickerstate: boolean) {
        let state = { ...this.store.get(imageAtomPrivate) };
        if (pickerstate === true) {
            state = {...initialState};
            fileUploadservice.setImagePreviews(undefined);
        }
        this.store.set(imageAtomPrivate, state);
    };

    setAlingData(data?: ImageAlignData) {

        const state = { ...this.store.get(imageAtomPrivate) };

        state.alignData = data;
        if (data) {
            state.imageAlign = data.imageAlign;
        }
        else {
            state.imageAlign = "none";
        }

        this.store.set(imageAtomPrivate, state);
    };


    setIsUploading(data: boolean) {

        const state = { ...this.store.get(imageAtomPrivate) };
        state.isUploading = data;
        this.store.set(imageAtomPrivate, state);
    };

    setDeleteRequested(data: boolean) {
        const state = { ...this.store.get(imageAtomPrivate) };
        state.deleteRequested = data;
        this.store.set(imageAtomPrivate, state);
    };

    setTab(tabState: tabstate) {

        const state = { ...this.store.get(imageAtomPrivate) };
        state.tab = tabState;
        this.store.set(imageAtomPrivate, state);
    };

    setSubTab(data: subtabstate) {
        const state = { ...this.store.get(imageAtomPrivate) };
        state.subTab = data;
        this.store.set(imageAtomPrivate, state);
    };

    setSelectedImage(id: string) {
        const state = { ...this.store.get(imageAtomPrivate) };
        if (state.imageData) {
            state.selectedImage = state.imageData.Images.find(i => i.ImageId === id);
            state.subTab = 'preview';
            this.store.set(imageAtomPrivate, state);
        }
    };

    setSelectedUser(userId?: string) {
        const state = { ...this.store.get(imageAtomPrivate) };
        if (state.imageData) {
            if (!userId) {
                state.filteredImages = [...state.imageData.Images];
            } else {
                state.filteredImages = state.imageData.Images.filter(i => i.User.Id === userId);
            }
            this.store.set(imageAtomPrivate, state);
        }
    };

    setImageAlign(align: align) {
        const state = { ...this.store.get(imageAtomPrivate) };
        state.imageAlign = align;
        this.store.set(imageAtomPrivate, state);
    };

    setImagePreviewWidth(width: number) {
        const state = { ...this.store.get(imageAtomPrivate) };
        state.imagePreviewWidth = width;
        this.store.set(imageAtomPrivate, state);
    };

    getImagePreviewWidth() {
        const state = { ...this.store.get(imageAtomPrivate) };
        return  state.imagePreviewWidth;
    };

    setNewImage(data: ImagePickerImageDto) {
        const state = { ...this.store.get(imageAtomPrivate) };
        if (state.imageData) {
            state.imageData.Images = [data, ...state.imageData.Images];
            state.filteredImages = [...state.imageData.Images];
            this.store.set(imageAtomPrivate, state);
        }

    };

    setCurrentImage(data?: ImagePickerImageData) {
        const state = { ...this.store.get(imageAtomPrivate) };
        state.currentImage = data;
        state.deleteRequested = false;
        this.store.set(imageAtomPrivate, state);
    };

    deleteCurrentImageInState(state: ImagePickerState) {

        if (!state.currentImage) {
            return;
        }
        if (state.imageData) {
            state.imageData.Images = state.imageData.Images.filter(i => i.ImageId !== state.currentImage?.Overview.Id);
            state.currentImage = undefined;
            state.filteredImages = [...state.imageData.Images];
            state.selectedImage = undefined;
            state.deleteRequested = false;
            this.store.set(imageAtomPrivate, state);
        }
    };

    setPermissions(data: ImagePickerPermissionDto) {
        const state = { ...this.store.get(imageAtomPrivate) };

        if (!state.currentImage) {
            return;
        }
        state.currentImage.Permissions = data;
        this.store.set(imageAtomPrivate, state);
    };



    /**
     *  get data for images
     * 
     */
    async fetchImageData() {
        const response = await api.get(urls.GET_IMAGES_DATA_URL);
        if (response != null) {
            this.setImagesData(response.data);
        };
    };

    /**
     * image uploaded to temp bucket
     * now it's time to add to server 
     * and to local state
     * 
     */
    async addImageToList(data: ImagePickerAddImagePostDto) {
        const serveData = await this.addImageToServer(data)
        if (serveData != null) {
            this.setNewImage(serveData);
            return serveData.ImageId;
        };

        return null;
    };

    /**
     * image uploaded to temp bucket
     * now it's time to add to server 
     * 
     */
    async addImageToServer(data: ImagePickerAddImagePostDto) {

        const response = await api.post(urls.ADDIMAGE_URL, data);
        if (response != null) {
            return response.data;
        };

        return null;
    };


    /**
    * image uploaded to temp bucket
    */
    async getImageInfo(imageId: string, currentuserid: string) {

        const state = this.store.get(imageAtomPrivate);
        const { selectedImage } = state;

        if (selectedImage?.User.Id !== currentuserid) {
            this.setCurrentImage(undefined);
            return;
        }

        const response = await api.get<ImagePickerImageData>(urls.GET_IMAGE_INFO_URL(imageId));
        if (response != null) {
            this.setCurrentImage(response.data);
        };
    };

    async deleteCurrentImage() {

        const state = this.store.get(imageAtomPrivate);
        const { currentImage } = state;
        if (!currentImage) return;

        const response = await api.delete(urls.GET_IMAGE_DELETE_URL(currentImage?.Overview.Id));
        if (response != null) {
            this.deleteCurrentImageInState(state);
            this.setTab('browse');
        };
    };


    async setPermission(type: 'unitroots' | 'unitadmins' | 'teachers', pstate: boolean) {

        const state = this.store.get(imageAtomPrivate);
        const { currentImage } = state;

        const data = {
            Value: pstate
        };

        let response;

        if (currentImage) {
            switch (type) {
                case 'unitroots':
                    response = await api.put(urls.GET_IMAGE_ROOTADMIN_PERMISSION_URL(currentImage.Overview.Id), data);
                    if (response) {
                        const newData = { ...currentImage.Permissions, UnitRootsCanRead: pstate }
                        this.setPermissions(newData);
                    }
                    break;

                case 'unitadmins':
                    response = await api.put(urls.GET_IMAGE_UNITADMIN_PERMISSION_URL(currentImage.Overview.Id), data);
                    if (response) {
                        const newData = { ...currentImage.Permissions, UnitAdminsCanRead: pstate }
                        this.setPermissions(newData);
                    }
                    break;

                case 'teachers':
                    response = await api.put(urls.GET_IMAGE_TEACHERS_PERMISSION_URL(currentImage.Overview.Id), data);
                    if (response) {
                        const newData = { ...currentImage.Permissions, TeachersCanRead: pstate }
                        this.setPermissions(newData);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    async addUserPermission(user: UserMailDto) {
        const state = this.store.get(imageAtomPrivate);
        const data = {
            Value: user.Id
        };

        const { currentImage } = state;

        if (!currentImage) return;


        const response = await api.put(urls.GET_IMAGE_ADD_USER_PERMISSION_URL(currentImage.Overview.Id), data);
        if (response) {
            const newData = { ...currentImage.Permissions, Users: [...currentImage.Permissions.Users, user] }
            this.setPermissions(newData);
        }
    };

    async removeUserPermission(userid: string) {

        const data = {
            Value: userid
        };

        const state = this.store.get(imageAtomPrivate);
        const { currentImage } = state;
        if (!currentImage) return;

        const response = await api.put(urls.GET_IMAGE_REMOVE_USER_PERMISSION_URL(currentImage.Overview.Id), data);
        if (response) {
            const newData = { ...currentImage.Permissions, Users: currentImage.Permissions.Users.filter(u => u.Id !== userid) };
            this.setPermissions(newData);
        }
    };

    async getUsersForPermissions(query: string, imageId: string) {
        const response = await api.get<UserMailDto[]>(urls.GET_IMAGE_SEARCH_USERS_URL(imageId, query));
        if (response) {
            return response.data;
        }

        return [];
    };

   


};


const imagePickerService = new ImagePickerService();

export default imagePickerService;


const urls = {
    GET_IMAGES_DATA_URL: "imagepicker/GetImages",
    ADDIMAGE_URL: "/imagepicker/AddImage",
    GET_IMAGE_INFO_URL: (id: string) => `/imagepicker/${id}/GetImageData`,

    GET_IMAGE_DELETE_URL: (id: string) => `/imagepicker/${id}/DeleteImage`,

    GET_IMAGE_ROOTADMIN_PERMISSION_URL: (id: string) => `/imagepicker/${id}/permission/SetUnitRootPermission`,
    GET_IMAGE_UNITADMIN_PERMISSION_URL: (id: string) => `/imagepicker/${id}/permission/SetUnitAdminPermission`,
    GET_IMAGE_TEACHERS_PERMISSION_URL: (id: string) => `/imagepicker/${id}/permission/SetTeacherPermission`,
    GET_IMAGE_ADD_USER_PERMISSION_URL: (id: string) => `/imagepicker/${id}/permission/AddPermission`,
    GET_IMAGE_REMOVE_USER_PERMISSION_URL: (id: string) => `/imagepicker/${id}/permission/RemovePermission`,

    GET_IMAGE_SEARCH_USERS_URL: (id: string, text: string) => `/imagepicker/${id}/permission/FindUsersByText?text=${text}`,


}