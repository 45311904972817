import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ImageScaler from "./ImageScaler";
import { useI18n } from "src/utils/lni18n";
import imagePickerService from "./ImagePickerService";


const ImageScalerDialog: React.FC<{ onSubmit: (d:any) => void, onCancel: () => void }> = ({ onSubmit, onCancel }) => {

    const { languageService: t } = useI18n();

    return (<>

        <ModalHeader data-dialog-draggable >{t.getText("image")}</ModalHeader>
        <ModalBody>

            <ImageScaler imagePickerService={imagePickerService} onSaveImage={()=>onSubmit({})} />

        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-inverse" onClick={onCancel}>{t.getText("close")}</Button>
        </ModalFooter>
    </>

    );
}

export default ImageScalerDialog;