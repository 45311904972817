import { useCallback, useEffect } from "react";
import { parseFileSize, getFileSizeString } from "../../utils/FileSizeUtils";
import { LanguageService } from "../../utils/languageService";
import { useAtom } from "jotai";
import { currentUser } from "../../services/user/userService";
import fileUploadservice, { fileUploadData } from "../FileUpload/FileUploadService";
import imagePickerService, { imageAtom } from "./ImagePickerService";
import { S3Uploader } from "../FileUpload/S3Uploader";
import imageDownScaler from "../FileUpload/ImageDownScaler";
import { useDialog } from "../Modalservice/Dialogservice";
import ImageScalerDialog from "./ImageScalerDialog";
import FileSaverDialog from "../FileUpload/FileSaverDialog";


export declare type ImageDropProps = {
    files: File[] |  FileList | null,
    onImageReady: (id: string) => void,
    accept: string,
    languageService: LanguageService,
    maxsize: number | string,
    imagePreviewOptions?: { name: string, maxwidth: number, maxheight: number }[],
    onCloseDialog: Function
}


const ImageDrop = (props: ImageDropProps) => {

    const { files, onImageReady, accept, languageService, maxsize, imagePreviewOptions, onCloseDialog } = props;
    const dialogPortal = useDialog();

    const t = languageService;

    const [imagePickerData] = useAtom(imageAtom);

    const maxSizeInBytes = parseFileSize(maxsize);
    const maxSizeString = getFileSizeString(maxSizeInBytes);

    const allowedTypes = accept.replace(/,/g, ", ");

    const [user] = useAtom(currentUser);


    const doCloseDialog = useCallback(() => {
        if (onCloseDialog) {
            onCloseDialog();
        }
    }, [onCloseDialog])



    useEffect(() => {
        fileUploadservice.setFile(undefined);
        // eslint-disable-next-line
    }, []);



    const tempUploadReady = useCallback(async (file: any) => {

        const data = {
            Filename: file.name,
            UploadedFilename: file.key,
            MaxWidth: imagePickerService.getImagePreviewWidth()
        }

        try {

            const serveData = await imagePickerService.addImageToServer(data);
            if (serveData) {
                onImageReady(serveData.ImageId);
            }

        }
        finally {
            fileUploadservice.setImagePreviews(undefined);
            imagePickerService.setIsUploading(false);
        }
    }, [imagePickerData.imagePreviewWidth]);

    const doUpload = (fi: File) => {

        fileUploadservice.startUploadState();

        const uploader = S3Uploader.startUpload(
            user!.Id,
            fi,
            (d: any) => {
                const percent = 100 * d.loaded / d.total;
                fileUploadservice.setPercentage(percent);
            });

        window.setTimeout(() => {
            dialogPortal({
                factory: (onSubmit, onCancel) => {
                    return <FileSaverDialog onCancel={onCancel} />
                },
                size: "md"
            }).catch((e)=>{});
        }, 20);


        uploader.promise.then((file: any) => {
            fileUploadservice.setUploading(false);
            let transformedFileData: any = {};
            Object.keys(file)
                .forEach(k => transformedFileData[k.toLowerCase()] = file[k]);

            transformedFileData.name = fi.name;
            transformedFileData.size = fi.size;
            transformedFileData.type = fi.type;
            transformedFileData.created = fi.lastModified;

            tempUploadReady(transformedFileData);

        }).catch(
            d => { console.log(d); throw (d); }
        );
    }


    useEffect(() => {

        if (!files || files.length === 0) {
            return;
        }

        const fi = files[0];

        if (fi.size > maxSizeInBytes) {
            fileUploadservice.setError(t.getText("file.too.big", fi.name, getFileSizeString(fi.size), maxSizeString));
            return;
        }

        const splits = fi.name.split(".");
        if (splits && splits.length > 1) {
            var ext = splits[splits.length - 1].toLowerCase();
            if (accept.indexOf(ext) < 0) {
                return;
            }
        }

        if (imagePreviewOptions != null && imagePreviewOptions.length > 0) {
            imageDownScaler(fi, imagePreviewOptions)
                .then(data => {
                    fileUploadservice.setImagePreviews(data)
                    showScaleDialog(() => doUpload(fi));
                });

        }
        // eslint-disable-next-line
    }, [onImageReady, accept, doCloseDialog, files, user, maxSizeInBytes, t, allowedTypes, maxSizeString, imagePreviewOptions]);


    const showScaleDialog = (uploadAction: () => void) => {
        dialogPortal({
            factory: (onSubmit, onCancel) => {
                return <ImageScalerDialog onSubmit={onSubmit} onCancel={onCancel} />
            },
            size: "xl"
        }).then((res: any) => {
            onCloseDialog();
            uploadAction();
        }).catch(() => {
            onCloseDialog();
        });


    }


    return (<div>Dropper</div>);
}

export default ImageDrop;